import { Box, Card, CardHeader, Chip, Typography, CardContent, List, ListItem, Divider, Theme } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { ListAlt, FreeBreakfast, SportsEsports, PhotoCamera, Explore, StorageOutlined } from '@material-ui/icons'
import i18next from 'i18next'
import React from 'react'
import { Refs } from '../../App'
import './about'

export default function About(
	props: {
		classes: ClassNameMap,
		cardOptions: { width: number, height: number, boxShadow: number, appBarHeight: number }
		refs: Refs,
		theme: Theme,
	},
): React.ReactElement {
	const classes = props.classes
	const cardOptions = props.cardOptions
	const refs = props.refs
	const theme = props.theme

	return (
		<Box id='about-me' className={classes.section} boxShadow={cardOptions.boxShadow}>
			<Card ref={refs.sections.about}>
				<CardHeader title={
					<Chip
						label={<Typography variant='h5' align='left' color='inherit'>{i18next.t('about-me.title')}</Typography>}
						icon={<ListAlt />}
						color="primary"
						variant='outlined'
					/>
				} />
				<CardContent>
					<Typography variant='body1' align='left' color='inherit'>{i18next.t('about-me.description')}</Typography>
					<List>
						<ListItem>
							<Typography variant='body1' align='left' color='inherit'>{i18next.t('about-me.data.birthday.title')}</Typography>
							<span className={classes.grow} />
							<Typography variant='body1' align='left' color='inherit'>{i18next.t('about-me.data.birthday.content')}</Typography>
						</ListItem>
						<Divider />
						<ListItem>
							<Typography variant='body1' align='left' color='inherit'>{i18next.t('about-me.data.residence.title')}</Typography>
							<span className={classes.grow} />
							<Typography variant='body1' align='left' color='inherit'>{i18next.t('about-me.data.residence.content')}</Typography>
						</ListItem>
						<Divider />
						<ListItem>
							<Typography variant='body1' align='left' color='inherit'>Email</Typography>
							<span className={classes.grow} />
							<Typography variant='body1' align='left' color='inherit'>
								<a href='mailto:dagoncalves99@gmail.com'>dagoncalves99@gmail.com</a>
							</Typography>
						</ListItem>
						<Divider />
					</List>
					<Box>
						<Chip
							label={<Typography variant='h5' align='left' color='inherit'>{i18next.t('about-me.hobbies.title')}</Typography>}
							icon={<FreeBreakfast />}
							color="primary"
							variant='outlined'
							style={{ margin: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px 0` }}
						/>
						<Box>
							<Chip
								label={<Typography variant='body1' align='center' color='inherit'>{i18next.t('about-me.hobbies.gaming')}</Typography>}
								icon={<SportsEsports />}
								color='default'
								variant='outlined'
								style={{ margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0` }}
							/>
							<Chip
								label={<Typography variant='body1' align='center' color='inherit'>{i18next.t('about-me.hobbies.photograph')}</Typography>}
								icon={<PhotoCamera />}
								color='default'
								variant='outlined'
								style={{ margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0` }}
							/>
							<Chip
								label={<Typography variant='body1' align='center' color='inherit'>{i18next.t('about-me.hobbies.travel')}</Typography>}
								icon={<Explore />}
								color='default'
								variant='outlined'
								style={{ margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0` }}
							/>
							<Chip
								label={<Typography variant='body1' align='center' color='inherit'>{i18next.t('about-me.hobbies.german')}</Typography>}
								icon={<img src="https://img.icons8.com/color/24/000000/germany-circular.png" />}
								color='default'
								variant='outlined'
								style={{ margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0` }}
							/>
							<Chip
								label={<Typography variant='body1' align='center' color='inherit'>{i18next.t('about-me.hobbies.self-hosting')}</Typography>}
								icon={<StorageOutlined />}
								color='default'
								variant='outlined'
								style={{ margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0` }}
							/>
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Box>
	)
}