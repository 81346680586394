import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import React from 'react'
import './background'

export default function Background(props: { classes: ClassNameMap }): React.ReactElement {
	return (
		<div className="wrapper" style={{
			position: 'fixed',
			zIndex: -3,
			height: '100%',
			width: '100%',
			// background: '-webkit-linear-gradient(top left, #2196f3 0%, #1fbbf7 100%)'
		}}>
			<ul>
				<li style={{left: '10%'}} className={props.classes.backgroundSquare}></li>
				<li style={{left: '25%'}} className={props.classes.backgroundSquare}></li>
				<li style={{left: '40%'}} className={props.classes.backgroundSquare}></li>
				<li style={{left: '60%', animationDirection: 'reverse'}} className={props.classes.backgroundSquare}></li>
				<li style={{left: '75%', animationDirection: 'reverse'}} className={props.classes.backgroundSquare}></li>
				<li style={{left: '90%', animationDirection: 'reverse'}} className={props.classes.backgroundSquare}></li>
			</ul>
			{/* <div className="man">
				<div className="body">
					<span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</span>
					<div className="base">
						<span></span>
						<div className="face"></div>
					</div>
				</div>
				<div className="longfazers">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div> */}
		</div>
	)
}