import React from 'react'

export const projects: Record<string, Project> = {
	'baixa-app': {
		title: 'Baixa.App',
		text: 'projects.baixa-app.text',
		extendedText: {
			i18n: 'projects.baixa-app.extended.all',
			components: [
				<br key={0}/>,
				<a key={1} target='_blank' rel='noreferrer' href='https://medium.com/nest-collective-writing-wall/development-process-summer-internship-2020-dae3e2b5a4fc'></a>,
				<a key={2} target='_blank' rel='noreferrer' href='https://www.baixa.app/'></a>
			]
		},
		video: `${process.env.PUBLIC_URL}/video/baixa.app.mp4`,
	},
	'criador-horarios': {
		title: 'Criador Horários IST',
		text: 'projects.criador-horarios.text',
		extendedText: {
			i18n: 'projects.criador-horarios.extended.all',
			components: [
				<br key={0}/>,
				<a key={1} target='_blank' rel='noreferrer' href='https://github.com/joaocmd/Criador-Horarios'></a>,
				<a key={2} target='_blank' rel='noreferrer' href='https://horarios.dang.pt/'></a>
			]
		},
		image: `${process.env.PUBLIC_URL}/img/criador-horarios.png`
	}
}

export type Project = {
    title: string
    text: string
    extendedText?: {i18n: string, components: JSX.Element[]}
    image?: string
    video?: string
}
