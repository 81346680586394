import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n/i18n'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

// TODO: Change scrollbar to use theme colors
const theme = createMuiTheme({
	palette: {
		// type: 'dark',
		secondary: {
			main: '#F91E13'
		}
	}
})

ReactDOM.render(
	// <React.StrictMode>
	<MuiThemeProvider theme={theme}>
		<I18nextProvider i18n={i18n}>
			<App />
		</I18nextProvider>
	</MuiThemeProvider>,
	// </React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
