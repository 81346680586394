import { Box, Card, Chip, Typography, CardContent, List, ListItem, Divider, Icon, Theme } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { ListAlt, BarChart, Build, Check, Computer, School, Work } from '@material-ui/icons'
import i18next from 'i18next'
import React from 'react'
import { Trans } from 'react-i18next'
import { Refs } from '../../App'
import { projects } from '../../data/projects'
import './resume'

export default function Resume(
	props: {
		classes: ClassNameMap,
		cardOptions: { width: number, height: number, boxShadow: number, appBarHeight: number },
		refs: Refs,
		theme: Theme,
	},
): React.ReactElement {
	const classes = props.classes
	const cardOptions = props.cardOptions
	const refs = props.refs
	const theme = props.theme

	return (
		<Box id='resume' className={classes.section} boxShadow={cardOptions.boxShadow}>
			<Card ref={refs.sections.resume}>
				<CardContent>
					<Chip
						label={<Typography variant='h5' align='left' color='inherit'>Curriculum Vitae</Typography>}
						icon={<ListAlt />}
						color="primary"
						variant='outlined'
					/>
					<List>
						<Box style={{ marginBottom: theme.spacing(1) }} className={classes.stickyTitle + ' stickyContainer'}>
							<Chip
								label={<Typography variant='h6' align='left' color='inherit'>{i18next.t('cv.experience.title')}</Typography>}
								icon={<Work />}
								color="primary"
								variant='outlined'
							/>
						</Box>
						<ListItem>
							<Card raised>
								<CardContent>
									<Typography variant='body1' align='left' color='inherit'>2022/12 - {i18next.t('cv.experience.now')}</Typography>
									<Typography variant='h6' align='left' color='inherit'>
										Tech Lead & Backend Developer
									</Typography>
									<Typography variant='caption' align='left' color='inherit' paragraph>
										{i18next.t('cv.experience.theloopco-2.title')}
									</Typography>
									<Trans
										i18nKey={'cv.experience.theloopco-2.description'}
										components={[<br key={0} />]}
									/>
								</CardContent>
							</Card>
						</ListItem>
						<ListItem>
							<Card raised>
								<CardContent>
									<Typography variant='body1' align='left' color='inherit'>2021/04 - 2022/11</Typography>
									<Typography variant='h6' align='left' color='inherit'>
										Backend Developer
									</Typography>
									<Typography variant='caption' align='left' color='inherit' paragraph>
										{i18next.t('cv.experience.theloopco.title')}
									</Typography>
									<Trans
										i18nKey={'cv.experience.theloopco.description'}
										components={[<br key={0} />]}
									/>
								</CardContent>
							</Card>
						</ListItem>
						<ListItem>
							<Card raised>
								<CardContent>
									<Typography variant='body1' align='left' color='inherit'>2020/07 - 2020/08</Typography>
									<Typography variant='h6' align='left' color='inherit'>
										Full Stack Developer
									</Typography>
									<Typography variant='caption' align='left' color='inherit' paragraph>
										{i18next.t('cv.experience.nest-si-2020.title')}
									</Typography>
									{/* <Typography variant='body1' align='left' color='inherit'>{projects['baixa-app'].extendedText}</Typography> */}
									<Trans
										i18nKey={projects['baixa-app'].extendedText?.i18n}
										components={projects['baixa-app'].extendedText?.components ?? []}
									/>
								</CardContent>
							</Card>
						</ListItem>
					</List>
					<List>
						<Box style={{ marginBottom: theme.spacing(1) }} className={classes.stickyTitle + ' stickyContainer'}>
							<Chip
								label={<Typography variant='h6' align='left' color='inherit'>{i18next.t('cv.education.title')}</Typography>}
								icon={<School />}
								color="primary"
								variant='outlined'
							/>
						</Box>
						<ListItem>
							<Card raised>
								<CardContent>
									<Typography variant='body1' align='left' color='inherit'>2020 - 2022</Typography>
									<Typography variant='h6' align='left' color='inherit'>
										{i18next.t('cv.education.master.title')}
									</Typography>
									<Typography variant='caption' align='left' color='inherit' paragraph>
										{i18next.t('cv.education.master.location')}
									</Typography>
									<Typography variant='body2' align='left' color='inherit'>
										{/* {i18next.t('cv.education.master.description')} */}
										<Trans
											i18nKey={'cv.education.master.description'}
											components={[<br key={0} />]}
										/>
									</Typography>
								</CardContent>
							</Card>
						</ListItem>
						<ListItem>
							<Card raised>
								<CardContent>
									<Typography variant='body1' align='left' color='inherit'>2017 - 2020</Typography>
									<Typography variant='h6' align='left' color='inherit'>
										{i18next.t('cv.education.licenciate.title')}
									</Typography>
									<Typography variant='caption' align='left' color='inherit' paragraph>
										{i18next.t('cv.education.licenciate.location')}
									</Typography>
									<Typography variant='body2' align='left' color='inherit'>
										{/* {i18next.t('cv.education.licenciate.description')} */}
										<Trans
											i18nKey={'cv.education.licenciate.description'}
											components={[<br key={0} />]}
										/>
									</Typography>
								</CardContent>
							</Card>
						</ListItem>
						<Divider />
					</List>
					{/* Skills */}
					<Box style={{ marginBottom: theme.spacing(1) }} className={classes.stickyTitle + ' stickyContainer'}>
						<Chip
							label={<Typography variant='h6' align='left' color='inherit'>Skills</Typography>}
							icon={<BarChart />}
							color="primary"
							variant='outlined'
							style={{ marginBottom: theme.spacing(1) }}
						/>
					</Box>
					<Card>
						<CardContent>
							<List
								subheader={
									<Chip
										label={<Typography variant='subtitle1' align='left' color='inherit'>{i18next.t('cv.skills.programming-languages')}</Typography>}
										icon={<Computer />}
										color="primary"
										variant='outlined'
									/>
								}
							>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Java</Typography>}
										icon={<Check />} size='small' color="default" variant='outlined' />
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Python</Typography>}
										icon={<Check />} size='small' color="default" variant='outlined' />
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Ruby</Typography>}
										icon={<Check />} size='small' color="default" variant='outlined' />
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Typescript/Javascript</Typography>}
										icon={<Check />} size='small' color="default" variant='outlined' />
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>HTML</Typography>}
										icon={<Check />} size='small' color="default" variant='outlined' />
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										disabled
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>CSS/SCSS</Typography>}
										icon={<Check />} size='small' color="default" variant='outlined' />
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>SQL</Typography>}
										icon={<Check />} size='small' color="default" variant='outlined' />
								</ListItem>
							</List>
						</CardContent>
					</Card>
					<Card style={{ marginTop: theme.spacing(1) }}>
						<CardContent>
							<List subheader={
								<Chip
									label={<Typography variant='subtitle1' align='left' color='inherit'>{i18next.t('cv.skills.frameworks-tools')}</Typography>}
									icon={<Build />}
									color="primary"
									variant='outlined'
								/>
							}>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Ruby On Rails</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='rubyonrails' src='img/icons/ruby.svg' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>React</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='react' src='img/icons/react.svg' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								{/* <ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Angular</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='angular' src='https://img.icons8.com/color/24/000000/angularjs.svg' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Vue</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='vue' src='https://img.icons8.com/color/24/000000/vue-js.svg' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem> */}
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Git</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/git.svg' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								{/* <ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>JavaScript</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/javascript.gif' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem> */}
								{/* <ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>HTML</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/html.png' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem> */}
								{/* <ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Java</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/java.gif' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem> */}
								{/* <ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>SQL</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/sql.png' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem> */}
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>PostgreSQL</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/postgres.svg' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Bash</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/bash.png' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Kubernetes</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/kubernetes.svg' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Ansible</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/ansible.png' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Rancher</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='git' src='img/icons/rancher.png' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								{/* <ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Maven</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='maven' src='http://maven.apache.org/favicon.ico' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem>
								<ListItem className={classes.listItemsSmaller}>
									<Chip
										className={classes.chipNoOutline}
										label={<Typography variant='body1' align='left' color='inherit'>Spring</Typography>}
										icon={<Icon><img style={{ height: '100%' }} alt='spring' src='img/icons/spring-boot.svg' /></Icon>}
										color='default'
										variant='outlined'
									/>
								</ListItem> */}
							</List>
						</CardContent>
					</Card>
				</CardContent>
			</Card>
		</Box>
	)
}