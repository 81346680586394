import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'

const TIME_CONSTANTS = { // in ms
	WRITE: 150,
	DELETE: 30,
	TIMEOUT: 10000,
}

const useStyles = makeStyles(() =>
	createStyles({
		cursor: {
			animation: '$blink .7s steps(1) infinite'
		},
		'@keyframes blink': {
			'50%': {
				borderColor: 'transparent'
			}
		}
	}),
)

const Typer = ({ title = '', dataText, color }: TyperProps): JSX.Element => {
	const [text, setText] = useState('')
	const [isDeleting, setIsDeleting] = useState(false)
	const [speed, setSpeed] = useState(TIME_CONSTANTS.WRITE)
	const [loop, setLoop] = useState(0)

	const i: number = loop % dataText.length
	const fullText: string = dataText[i]

	const classes = useStyles()

	const handleTyping = () => {
		setText(isDeleting
			? fullText.substring(0, text.length - 1)
			: fullText.substring(0, text.length + 1)
		)

		setSpeed(isDeleting ? TIME_CONSTANTS.DELETE : TIME_CONSTANTS.WRITE)

		if (!isDeleting && text === fullText) {
			setTimeout(() => setIsDeleting(true), TIME_CONSTANTS.TIMEOUT)
		} else if (isDeleting && text === '') {
			setIsDeleting(false)
			setLoop(loop + 1)
		}
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			handleTyping()
		}, speed)
		return () => clearTimeout(timer)
	})

	return (
		<div>
			<span>
				{title}&nbsp;{text}
			</span>
			<span id="cursor" className={classes.cursor} style={{borderLeft: `.15em solid ${color}`}} />
		</div>
	)
}

interface TyperProps {
	dataText: string[]
	title?: string
	theme: Theme
	color: string
}

export default Typer