import { Box, Card, Chip, Typography, CardContent, ListItem, Button, Dialog, DialogActions, DialogContent, Theme } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { Create } from '@material-ui/icons'
import i18next from 'i18next'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { Refs } from '../../App'
import { Project, projects } from '../../data/projects'
import './portfolio'

export default function Portfolio(
	props: {
		classes: ClassNameMap,
		cardOptions: { width: number, height: number, boxShadow: number, appBarHeight: number },
		refs: Refs,
		theme: Theme,
	},
): React.ReactElement {
	const classes = props.classes
	const cardOptions = props.cardOptions
	const refs = props.refs
	const theme = props.theme

	const [currProject, setProject] = useState<Project | undefined>(undefined)
	const [portfolioDialog, setPortfolioDialog] = useState(false)

	return (
		<>
			<Box id='portfolio' className={classes.section} boxShadow={cardOptions.boxShadow}>
				<Card ref={refs.sections.portfolio}>
					<CardContent>
						<Box style={{ marginBottom: theme.spacing(1) }} className={classes.stickyTitle + ' stickyContainer'}>
							<Chip
								label={<Typography variant='h5' align='left' color='inherit'>Portfolio</Typography>}
								icon={<Create />}
								color="primary"
								variant='outlined'
							/>
						</Box>
						<ListItem>
							<Card raised onClick={() => {
								setProject(projects['criador-horarios'])
								setPortfolioDialog(true)
							}} className={classes.clickable}>
								<img alt="" style={{ width: '100%' }} src={projects['criador-horarios'].image} />
								<CardContent>
									<Typography variant='h6' align='center' color='inherit'>{projects['criador-horarios'].title}</Typography>
									<Typography variant='body1' align='left' color='inherit'>
										{i18next.t(projects['criador-horarios'].text)}
									</Typography>
								</CardContent>
							</Card>
						</ListItem>
						<ListItem>
							<Card raised onClick={() => {
								setProject(projects['baixa-app'])
								setPortfolioDialog(true)
							}} className={classes.clickable}>
								<video autoPlay loop style={{ width: '100%' }}>
									<source src={projects['baixa-app'].video} type="video/mp4" />
								</video>
								{/* <CardHeader title={} /> */}
								<CardContent>
									<Typography variant='h6' align='center' color='inherit'>{projects['baixa-app'].title}</Typography>
									<Typography variant='body1' align='left' color='inherit'>{i18next.t(projects['baixa-app'].text)}</Typography>
								</CardContent>
							</Card>
						</ListItem>
					</CardContent>
				</Card>
			</Box>
			<Dialog open={portfolioDialog}
				onClose={() => { setPortfolioDialog(false) }}
				maxWidth={'lg'}
				fullWidth={false}
				style={{ whiteSpace: 'pre-line', maxHeight: '100%' }}
			>
				<DialogContent style={{ padding: 0 }}>
					{currProject?.video &&
						<video autoPlay controls loop style={{ width: '100%' }}>
							<source src={currProject?.video} type="video/mp4" />
						</video>
					}
					{currProject?.image &&
						<img alt="" style={{ width: '100%' }} src={currProject?.image} />
					}
					<Box style={{ padding: theme.spacing(1) }}>
						<Typography variant='h5' paragraph>{currProject?.title}</Typography>
						{/* <Typography>{currProject?.extendedText ?? currProject?.text}</Typography> */}
						<Trans
							i18nKey={currProject?.extendedText?.i18n ?? currProject?.text}
							components={currProject?.extendedText?.components ?? []}
						/>
					</Box>
				</DialogContent>
				<DialogActions style={{ backgroundColor: `${theme.palette.grey[100]}` }}>
					<div />
					<Button variant={'contained'} onClick={() => { setPortfolioDialog(false) }} color="primary">{i18next.t('project-dialog.actions.close')}</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}