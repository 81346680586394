import React, { useEffect, useRef, useState } from 'react'
import './App.scss'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import i18next from 'i18next'

import Background from './components/background/background'

import {
	AppBar, Backdrop, Box, Button,
	Card, CardActionArea, CircularProgress, Container, Grid, Hidden,
	IconButton, Toolbar, Typography
} from '@material-ui/core'
import {
	Description, Home, Mail, Person, Work
} from '@material-ui/icons'

import Typer from './utils/textAnimation'
import About from './components/about/about'
import Resume from './components/resume/resume'
import Portfolio from './components/portfolio/portfolio'
import Contact from './components/contact/contact'
import Hero from './components/hero/hero'

const changeToDesktop = 'md'
const cardOptions = {
	width: 400,
	height: 600,
	boxShadow: 0,
	appBarHeight: 117 + 40
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		body: {
			whiteSpace: 'pre-wrap'
		},
		appbar: {
			[theme.breakpoints.up(changeToDesktop)]: {
				position: 'fixed',
				display: 'flex',
				flexDirection: 'column',
				// left: '3%',
				left: `calc(50vw - ${cardOptions.width}px - ${theme.mixins.toolbar.minHeight as number * 1.3}px - 15px)`,
				// top: '10%',
				top: `calc(50vh - ${cardOptions.height / 2}px)`,
				width: `${theme.mixins.toolbar.minHeight as number * 1.3}px`,
				'& > .MuiToolbar-root': {
					flexDirection: 'column',
				}
			}
		},
		toolbarTitle: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			whiteSpace: 'pre-wrap'
		},
		links: {
			padding: 0,
			alignItems: 'flex-end',
			[theme.breakpoints.up(changeToDesktop)]: {
				alignItems: 'center',
			}
		},
		toolbarLinks: {
			width: '100%',
			// height: '100%',
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
			'&.active': {
				color: theme.palette.info.main
			}
		},
		cardLinks: {
			width: '100%',
			// height: '100%',
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
			padding: '8px 0',
			textTransform: 'uppercase'
		},
		grow: {
			flexGrow: 1,
		},
		mainBody: {
			// height: `calc(100% - ${(theme.mixins.toolbar.minHeight) as number *2}px)`,
		},
		profileDiv: {
			// height: 'calc(100vh - 157px)',
			height: `calc(100vh - ${cardOptions.appBarHeight}px)`,
			// width: 'calc(100vw - 40px)',
			padding: '20px',
			[theme.breakpoints.up(changeToDesktop)]: {
				height: '100%',
				width: '100%',
				zIndex: 10,
				// padding: '1px',
				padding: '0px',
				boxShadow: `-10px -10px ${theme.palette.primary.main}`,
				display: 'flex',
				flexDirection: 'column',
				position: 'relative'
			}
		},
		profileBoxPhoto: {
			display: 'flex',
			placeContent: 'center',
			[theme.breakpoints.up(changeToDesktop)]: {
				height: '50%',
				padding: '20px 0 20px 0',
				backgroundImage: `url("${process.env.PUBLIC_URL}/img/azores.jpg")`,
				backgroundSize: 'cover',
				borderBottom: `3px ${theme.palette.primary.main} solid`,
				'&::before': {
					content: '""',
					position: 'absolute',
					top: '60%',
					right: '-8%',
					width: '100%',
					height: '20%',
					transform: 'rotate(-20deg)',
					zIndex: 0,
					backgroundColor: `${theme.palette.background.default}`,
					borderTop: `3px ${theme.palette.primary.main} solid`,
					borderRadius: '15%'
				}
			}
		},
		profilePhoto: {
			// FIXME:
			width: '25%',
			height: '30%',
			display: 'none',
			// margin: 'auto',
			alignSelf: 'center',
			// height: 'calc(100% - 80px)',
			border: `3px ${theme.palette.primary.main} solid`,
			[theme.breakpoints.up(changeToDesktop)]: {
				display: 'inherit',
				width: '200px',
				height: '200px',
				// marginTop: '20px'
			},
			'@media (orientation: portrait)': {
				display: 'flex',
				width: '50%',
				height: '50%',
			}
		},
		profileTitle: {
			zIndex: 10,
			backgroundColor: `${theme.palette.background.default}`,
			paddingTop: '10px',
			[theme.breakpoints.up(changeToDesktop)]: {
				flexGrow: 1
			},
		},
		profileButtons: {
			position: 'absolute',
			bottom: '0',
			left: '0',
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			[theme.breakpoints.up(changeToDesktop)]: {
				// left: 'auto',
				// width: '80%',
				position: 'relative',
				// margin: 'auto',
				left: '-1px',
				bottom: '-1px',
				width: '101%',
			}
		},
		section: {
			marginTop: '16px',
			[theme.breakpoints.up(changeToDesktop)]: {
				height: '100%',
				position: 'absolute',
				left: 0,
				opacity: 0,
				transition: 'left 1s ease .1s, box-shadow 1s ease .1s, opacity 1s ease .1s',
				zIndex: 1,
				width: '100%',
				marginTop: theme.spacing(0),
				'&.active': {
					opacity: 1,
					left: `${cardOptions.width + 3}px`,
					boxShadow: `10px 10px ${theme.palette.secondary.main}`,
				},
				'& .MuiCard-root': {
					height: '100%',
					// overflowY: 'overlay',
					overflowY: 'auto',
					padding: '1px',
					// padding: '20px',
					'&:hover': {
						// overflowY: 'auto',
					}
				}
			}
		},
		fullButton: {
			width: '100%',
			borderRadius: '0',
			padding: '12px',
			display: 'flex',
			flexDirection: 'column'
		},
		noMarginPadding: {
			margin: 0,
			padding: 0
		},
		clickable: {
			cursor: 'pointer'
		},
		listItemsSmaller: {
			paddingBottom: 0,
			paddingLeft: theme.spacing(1)
		},
		chipNoOutline: {
			border: 'none'
		},
		stickyTitle: {
			[theme.breakpoints.up(changeToDesktop)]: {
				// position: 'sticky, -webkit-sticky',
				top: '4px',
				zIndex: 1,
				'& > *': {
					backgroundColor: `${theme.palette.background.default}`
				}
			}
		},
		desktopCard: {
			[theme.breakpoints.up(changeToDesktop)]: {
				position: 'absolute',
				// top: '10%',
				top: `calc(50vh - ${cardOptions.height / 2}px)`,
				right: '50%',
				width: `${cardOptions.width}px`,
				height: `${cardOptions.height}px`,
			}
		},
		backgroundSquare: {
			listStyle: 'none',
			opacity: 0,
			[theme.breakpoints.up(changeToDesktop)]: {
				position: 'absolute',
				top: '-100px',
				width: '80px',
				height: '80px',
				animation: 'squareRotation 25s infinite linear',
				animationDelay: '0s',
				opacity: '0.5',
				boxShadow: '4px 4px #0d47a1',
				backgroundColor: '#81d4fa',
				'&:nth-child(2n)': {
					borderRadius: '50%',
					boxShadow: '4px 4px 0 4px #0d47a1',
				},
				'&:nth-child(3n+1)': {
					animationDelay: '2s',
					animationDuration: '17s'
				},
				'&:nth-child(3n-1)': {
					animationDelay: '10s',
					animationDuration: '13s'
				},
				'&:nth-child(3n-2)': {
					width: '40px',
					height: '40px',
				}
			}
		}
	}),
)

let lastElementActive: string | null = null

function isInViewport(offset: number, element: React.MutableRefObject<HTMLElement | undefined>) {
	const top = element?.current?.getBoundingClientRect().top ?? 0
	const bottom = element?.current?.getBoundingClientRect().bottom ?? 0
	const isTopVisible = (top + offset) >= 0 && (top - offset) <= window.innerHeight
	const isBottomVisible = (bottom - offset) >= 0 && (bottom + offset) <= window.innerHeight
	const isBetween = (top <= 0) && (bottom >= window.innerHeight)
	return isTopVisible || isBottomVisible || (!isTopVisible && !isBottomVisible && isBetween)
}

export type Refs = {
	sections: {
		about: React.MutableRefObject<HTMLElement | undefined>,
		resume: React.MutableRefObject<HTMLElement | undefined>,
		portfolio: React.MutableRefObject<HTMLElement | undefined>,
		contact: React.MutableRefObject<HTMLElement | undefined>
	},
	buttons: {
		about: React.MutableRefObject<HTMLElement | undefined>,
		resume: React.MutableRefObject<HTMLElement | undefined>,
		portfolio: React.MutableRefObject<HTMLElement | undefined>,
		contact: React.MutableRefObject<HTMLElement | undefined>
	}
}

function App(): React.ReactElement {
	const theme = useTheme()
	const classes = useStyles()
	const refs: Refs = {
		sections: {
			about: useRef<HTMLElement>(),
			resume: useRef<HTMLElement>(),
			portfolio: useRef<HTMLElement>(),
			contact: useRef<HTMLElement>()
		},
		buttons: {
			about: useRef<HTMLElement>(),
			resume: useRef<HTMLElement>(),
			portfolio: useRef<HTMLElement>(),
			contact: useRef<HTMLElement>()
		}
	}

	// FIXME: When on scrollbottom it still shows resume when it is not showed
	useEffect(() => {
		function onScroll() {
			Object.keys(refs.sections).forEach((v) => {
				if (isInViewport(0, refs.sections[v as keyof typeof refs.sections])) {
					refs.buttons[v as keyof typeof refs.buttons].current?.classList.add('active')
				} else {
					refs.buttons[v as keyof typeof refs.buttons].current?.classList.remove('active')
				}
			})
		}

		window.addEventListener('scroll', onScroll)
		onHeaderButtonClick(undefined, 'about')
		return () => window.removeEventListener('scroll', onScroll)
	}, [])

	function onHeaderButtonClick(event: React.MouseEvent | undefined, elementName: string): void {
		const position = refs.sections[elementName as keyof typeof refs.sections].current?.getBoundingClientRect() ?? {
			left: 0,
			top: 0
		}
		const padding = (theme.mixins.toolbar.minHeight) as number * 2

		// refs.sections[elementName as keyof typeof refs.sections].current?.scrollIntoView({behavior: 'smooth'})
		const parent = refs.sections[elementName as keyof typeof refs.buttons].current?.parentElement
		if (!parent?.classList.contains('active') && elementName !== lastElementActive) {
			if (lastElementActive !== null) {
				refs.sections[lastElementActive as keyof typeof refs.buttons].current?.parentElement?.classList.toggle('active')
				refs.buttons[lastElementActive as keyof typeof refs.buttons].current?.classList.toggle('active')
			}
			lastElementActive = elementName
		} else if (parent?.classList.contains('active') && lastElementActive === elementName) {
			// lastElementActive = null
		}

		if (!parent?.classList.contains('active')) {
			parent?.classList.add('active')
			refs.buttons[elementName as keyof typeof refs.buttons].current?.classList.add('active')
		}

		// parent?.classList.toggle('active')
		// refs.buttons[elementName as keyof typeof refs.buttons].current?.classList.toggle('active')

		// For mobile
		window.scrollTo({ behavior: 'smooth', left: position.left, top: position.top + window.scrollY - padding })
	}

	const [lang, setLang] = useState('en')
	const [loading, setLoading] = useState(true)

	return (
		<div>
			<Background classes={classes} />
			<Backdrop open={loading} style={{ zIndex: 5000, backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box className={classes.body}>
				<AppBar className={classes.appbar} position="sticky" color="primary">
					<Toolbar>
						<Hidden mdUp>
							<Box className={classes.toolbarTitle}>
								<Typography color='inherit'>
									&nbsp;Daniel Gonçalves{'\n'}
									<Typer theme={theme} dataText={i18next.t('typer.titles', { returnObjects: true })} color={theme.palette.primary.contrastText} />
								</Typography>
							</Box>
							<IconButton edge="start" color="inherit" aria-label="menu" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>
								<Home />
							</IconButton>
						</Hidden>
						<Hidden smDown>
							<Box className={classes.toolbarTitle}>
								<Typography color='inherit' align={'center'} style={{ margin: 'auto' }} variant={'h5'}>DG</Typography>
							</Box>
						</Hidden>
						<Hidden mdUp>
							<Button variant={'outlined'} style={{ marginBottom: '4px' }} color="inherit" onClick={() => {
								const newLang = lang === 'en' ? 'pt' : 'en'
								i18next.changeLanguage(newLang).then(() => {
									i18next.options.lng = newLang
									setLang(newLang)
								})
							}}>{lang === 'pt' ? 'EN' : 'PT'}</Button>
						</Hidden>
					</Toolbar>
					<Toolbar className={classes.links}>
						{/* <Paper elevation={0} variant="outlined" square className={classes.toolbarLinks}>
							<IconButton color="inherit" aria-label="menu" title='about'>
								<Person />
							</IconButton>
						</Paper> */}
						<Card elevation={0} variant="outlined" square className={classes.toolbarLinks} onClick={(e) => onHeaderButtonClick(e, 'about')}
							ref={refs.buttons.about}
						>
							<CardActionArea className={classes.cardLinks}>
								<IconButton color="inherit" aria-label="menu" disableRipple className={classes.noMarginPadding}>
									<Person />
								</IconButton>
								<Typography variant='caption' align='center' color='inherit'>{i18next.t('appbar.button.about')}</Typography>
							</CardActionArea>
						</Card>
						<Card elevation={0} variant="outlined" square className={classes.toolbarLinks} onClick={(e) => onHeaderButtonClick(e, 'resume')}
							ref={refs.buttons.resume}
						>
							<CardActionArea className={classes.cardLinks}>
								<IconButton color="inherit" aria-label="menu" disableRipple className={classes.noMarginPadding}>
									<Description />
								</IconButton>
								<Typography variant='caption' align='center' color='inherit'>{i18next.t('appbar.button.resume')}</Typography>
							</CardActionArea>
						</Card>
						<Card elevation={0} variant="outlined" square className={classes.toolbarLinks} onClick={(e) => onHeaderButtonClick(e, 'portfolio')}
							ref={refs.buttons.portfolio}
						>
							<CardActionArea className={classes.cardLinks}>
								<IconButton color="inherit" aria-label="menu" disableRipple className={classes.noMarginPadding}>
									<Work />
								</IconButton>
								<Typography variant='caption' align='center' color='inherit'>{i18next.t('appbar.button.portfolio')}</Typography>
							</CardActionArea>
						</Card>
						<Card elevation={0} variant="outlined" square className={classes.toolbarLinks} onClick={(e) => onHeaderButtonClick(e, 'contact')}
							ref={refs.buttons.contact}
						>
							<CardActionArea className={classes.cardLinks}>
								<IconButton color="inherit" aria-label="menu" disableRipple className={classes.noMarginPadding}>
									<Mail />
								</IconButton>
								<Typography variant='caption' align='center' color='inherit'>{i18next.t('appbar.button.contact')}</Typography>
							</CardActionArea>
						</Card>
					</Toolbar>
					<Hidden smDown>
						{/* Language button on desktop */}
						<Button variant={'contained'} style={{ marginTop: `${theme.spacing(1)}px`, backgroundColor: `${theme.palette.background.default}` }} color="default" onClick={() => {
							const newLang = lang === 'en' ? 'pt' : 'en'
							i18next.changeLanguage(newLang).then(() => {
								i18next.options.lng = newLang
								setLang(newLang)
							})
						}}>{lang === 'pt' ? 'EN' : 'PT'}</Button>
					</Hidden>
				</AppBar>
				<Container maxWidth={changeToDesktop} disableGutters className={classes.mainBody}>
					<Grid container>
						<Grid item lg={6} className={classes.desktopCard} style={{ zIndex: 10 }}>
							<Hero classes={classes} cardOptions={cardOptions} refs={refs} theme={theme} lang={lang} loading={loading} setLoading={setLoading} onHeaderButtonClick={onHeaderButtonClick} />
						</Grid>
						<Grid item lg={6} className={classes.desktopCard}>
							<About classes={classes} cardOptions={cardOptions} refs={refs} theme={theme} />
							<Resume classes={classes} cardOptions={cardOptions} refs={refs} theme={theme} />
							<Portfolio classes={classes} cardOptions={cardOptions} refs={refs} theme={theme} />
							<Contact classes={classes} cardOptions={cardOptions} refs={refs} theme={theme} lang={lang} />
						</Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	)
}

export default App
